<template>
  <div class="project-sales-booking-actions">
    <!-- Action Buttons -->
    <div class="col-12 mt-2 text-right">
      <booking-detail-action-buttons
        :status="sales.projectPurchaseBooking.status"
        :canManage="canManage"
        :canApprove="canApprove"
        @openApproveModal="openApproveModal(sales.id)"
        @openRejectModal="openRejectModal(sales.id)"
        @openCancelModal="openCancelModal(sales.id)"
        @viewRejectedReason="viewRejectedReason(sales.id)"
      ></booking-detail-action-buttons>
    </div>
    <!-- ===================== Modal: Approve Booking ====================== -->
    <modal v-model="approveBookingModal.isShown">
      <approve-booking
        :bookingId="approveBookingModal.id"
        @cancel="approveBookingModal.isShown = false"
        @submit="approveBooking"
      ></approve-booking>
    </modal>
    <!-- ===================== Modal: Reject Booking ======================= -->
    <modal v-model="rejectBookingModal.isShown">
      <reject-booking
        :bookingId="rejectBookingModal.id"
        @cancel="rejectBookingModal.isShown = false"
        @submit="rejectBooking"
      ></reject-booking>
    </modal>
    <!-- ===================== Modal: Cancel Booking ======================= -->
    <modal v-model="cancelBookingModal.isShown">
      <cancel-booking
        :bookingId="cancelBookingModal.id"
        @cancel="cancelBookingModal.isShown = false"
        @submit="cancelBooking"
      ></cancel-booking>
    </modal>
    <!-- ===================== Modal: Reject Reason ======================== -->
    <modal v-model="rejectReasonModal.isShown">
      <reason
        :type="projectBookingStatus.REJECTED"
        :bookingId="rejectReasonModal.id"
        @cancel="rejectReasonModal.isShown = false"
      ></reason>
    </modal>
  </div>
</template>

<script>
import { projectBookingStatus } from "@/enums";

export default {
  components: {
    BookingDetailActionButtons: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Booking/BookingDetailActionButtons"
      ),
    // Modal Contents
    ApproveBooking: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Booking/ApproveBooking"
      ),
    RejectBooking: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Booking/RejectBooking"
      ),
    CancelBooking: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Booking/CancelBooking"
      ),
    Reason: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Booking/RejectCancelReason"
      )
  },
  mixins: [],
  props: {
    sales: {
      type: Object,
      required: true
    },
    canManage: {
      type: Boolean,
      default: false
    },
    listingRouteName: {
      required: true
    },
    getBookingDetail: {
      required: true
    },
    approveBookingAPI: {
      type: Function,
      required: true
    },
    rejectBookingAPI: {
      type: Function,
      required: true
    },
    cancelBookingAPI: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      projectBookingStatus,

      approveBookingModal: {
        id: "",
        isShown: false
      },
      rejectBookingModal: {
        id: "",
        isShown: false
      },
      cancelBookingModal: {
        id: "",
        isShown: false
      },
      rejectReasonModal: {
        id: "",
        isShown: false
      }
    };
  },
  computed: {
    canApprove() {
      return (
        this.sales.projectPurchaseBooking.paymentReceipt.length > 0 &&
        this.sales.projectPurchaseBooking.saleForm.length > 0 &&
        this.sales.projectPurchaseBooking.purchaserIdentityCardOrPassport
          .length > 0
      );
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    // Approve Booking
    openApproveModal(id) {
      this.approveBookingModal.id = id;
      this.approveBookingModal.isShown = true;
    },
    async approveBooking(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.approveBookingAPI(id, payload);

        this.$router.push({ name: this.listingRouteName });

        this.approveBookingModal.isShown = false;

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Booking has been approved successfully."
        });
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to approve booking. Please try again later."
        });
      }
    },
    // Reject Booking
    openRejectModal(id) {
      this.rejectBookingModal.id = id;
      this.rejectBookingModal.isShown = true;
    },
    async rejectBooking(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.rejectBookingAPI(id, payload);

        this.rejectBookingModal.isShown = false;
        this.$router.push({ name: this.listingRouteName });

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Booking has been rejected successfully."
        });
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to reject booking. Please try again later."
        });
      }
    },
    // Cancel Booking
    openCancelModal(id) {
      this.cancelBookingModal.id = id;
      this.cancelBookingModal.isShown = true;
    },
    async cancelBooking(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.cancelBookingAPI(id, payload);

        this.cancelBookingModal.isShown = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Booking has been cancelled successfully."
        });

        await this.getBookingDetail();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to cancel booking. Please try again later."
        });
      }
    },
    // View Reason
    viewRejectedReason(id) {
      this.rejectReasonModal.id = id;
      this.rejectReasonModal.isShown = true;
    }
  }
};
</script>

<style lang="scss"></style>
